import { privateAPi } from '.';

export const authApi = privateAPi.injectEndpoints({
  endpoints: build => ({
    authorized: build.query({
      query: () => '/api/auth/user',
      providesTags: ['GetAuthorizedUser'],
    }),
  }),
});

export const { useAuthorizedQuery } = authApi;

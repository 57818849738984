import React, { useEffect } from 'react';
import { ThemeProvider } from '@mui/material';
import { SnackbarProvider } from 'notistack';
import { useDispatch } from 'react-redux';

// API HOOKS
import { useAuthorizedQuery } from 'services/private/auth';

// REDUX ACTIONS
import { onAuthorized, onLoggedOut } from 'store/slices/authSlice';

// THEME STYLES
import theme from 'styles/mui/generalCustomTheme';

// ROUTES
import AppRoutes from './routes';

function App() {
  const dispatch = useDispatch();
  const { data, isSuccess, isLoading, isError } = useAuthorizedQuery();

  useEffect(() => {
    if (isSuccess) {
      dispatch(onAuthorized(data));
    } else if (isError) dispatch(onLoggedOut());
  }, [data, isLoading, isSuccess]);

  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} autoHideDuration={2000}>
        {!isLoading && <AppRoutes />}
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default App;

/* eslint-disable function-paren-newline */
/* eslint-disable implicit-arrow-linebreak */
import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom';

import GlobalLoader from 'containers/common/loaders/GlobalLoader';
import AddInfoContextProvider from 'containers/portal/onboard/add-info/context/AddInfoContext';
import { JobContextProvider } from 'containers/portal/hr/Jobs/context/JobContext';
import ScrollToTop from './ScrollToTop';

const LayoutWrapper = lazy(() => import('containers/common/layout'));
const PrivateRoutes = lazy(() => import('./PrivateRoutes'));
const PublicRoutes = lazy(() => import('./PublicRoutes'));
const OnboardRoutes = lazy(() => import('./OnboardRoutes'));

// AUTH ROUTES & MISCELLANEOUS ROUTES
const Login = lazy(() => import('containers/public-pages/login'));
const ForgotPassword = lazy(() => import('containers/public-pages/forgot-password'));
const ResetPassword = lazy(() => import('containers/public-pages/reset-password'));
const Unauthorized = lazy(() => import('containers/portal/misc/Unauthorized'));

// PUBLIC ROUTES
const Homepage = lazy(() => import('containers/public-pages/home'));
const Careers = lazy(() => import('containers/public-pages/careers'));
const CareerDetails = lazy(() => import('containers/public-pages/careers/career-details'));
const ApplyJob = lazy(() => import('containers/public-pages/careers/apply-job'));

// PORTAL ROUTES
const Dashboard = lazy(() => import('containers/portal/dashboard'));
const CompanyProfiles = lazy(() => import('containers/portal/hr/company/profiles'));
const AddCompanyProfile = lazy(() =>
  import('containers/portal/hr/company/profiles/components/AddCompanyProfile')
);
const UserProfile = lazy(() => import('containers/portal/user/profile'));
const Salary = lazy(() => import('containers/portal/hr/payroll/salary'));
const ViewSalaryDetails = lazy(() =>
  import('containers/portal/hr/payroll/salary/Employee/components/ViewSalaryDetails')
);
const Employees = lazy(() => import('containers/portal/hr/employees'));
const EmployeeProfile = lazy(() => import('containers/portal/hr/employee-profile'));
const AddEmployee = lazy(() => import('containers/portal/hr/add-employee'));
const Trash = lazy(() => import('containers/portal/storage/trash'));
const Settings = lazy(() => import('containers/portal/settings'));
const AddSettings = lazy(() => import('containers/portal/settings/components/AddSettings'));
const Calendar = lazy(() => import('containers/portal/user/calendar'));
const Signature = lazy(() => import('containers/portal/user/signature'));
const Storage = lazy(() => import('containers/portal/storage/main'));
const Teams = lazy(() => import('containers/portal/teams'));
const AddTeam = lazy(() => import('containers/portal/teams/add'));
const TeamDetail = lazy(() => import('containers/portal/teams/details'));
const EditTeam = lazy(() => import('containers/portal/teams/edit'));
const Projects = lazy(() => import('containers/portal/projects'));
const AddProject = lazy(() => import('containers/portal/projects/add'));
const ProjectDetail = lazy(() => import('containers/portal/projects/details'));
const EditProject = lazy(() => import('containers/portal/projects/edit'));
const ClientsAccessWrapper = lazy(() => import('containers/portal/client/components/ClientsAccessWrapper'));
const Clients = lazy(() => import('containers/portal/client'));
const AddClient = lazy(() => import('containers/portal/client/add'));
const ClientDetail = lazy(() => import('containers/portal/client/details'));
const EditClient = lazy(() => import('containers/portal/client/edit'));
const Requests = lazy(() => import('containers/portal/requests'));
const DocumentRequests = lazy(() => import('containers/portal/requests/document-requests'));
const HalfDayRequests = lazy(() => import('containers/portal/requests/half-day-requests'));
const LeaveRequests = lazy(() => import('containers/portal/requests/leave-requests'));
const WorkFromHomeRequests = lazy(() => import('containers/portal/requests/work-from-home-requests'));
const CompanyFiles = lazy(() => import('containers/portal/hr/company/files'));
const EditCompanyProfile = lazy(() =>
  import('containers/portal/hr/company/profiles/components/EditCompanyProfile')
);
const CompanyHolidays = lazy(() => import('containers/portal/hr/company/holidays'));
const ResourceRequired = lazy(() => import('containers/portal/requests/resource-required'));
const Engagements = lazy(() => import('containers/portal/engagements'));
const FinalPay = lazy(() => import('containers/portal/hr/payroll/final-pay'));
const ViewFinalPayForm = lazy(() =>
  import('containers/portal/hr/payroll/final-pay/components/ViewFinalPayForm')
);

const AdminAccessWrapper = lazy(() => import('containers/common/components/AdminAccessWrapper'));
const InterviewAccessWrapper = lazy(() => import('containers/common/components/InterviewAccessWrapper'));
const Jobs = lazy(() => import('containers/portal/hr/Jobs'));
const AddJob = lazy(() => import('containers/portal/hr/Jobs/AddJob'));
const JobDetail = lazy(() => import('containers/portal/hr/Jobs/JobDetail'));
const EditJob = lazy(() => import('containers/portal/hr/Jobs/EditJob'));
const Interviews = lazy(() => import('containers/interviews'));
const Todos = lazy(() => import('containers/portal/todos'));

// GUEST ROUTES
const OfferLetter = lazy(() => import('containers/guest-pages/offer-letter'));

const CreateNewHirePacket = lazy(() => import('containers/portal/hr/hire-packet'));
const ViewHirePacket = lazy(() => import('containers/portal/hr/hire-packet/view-hire-packet'));
const OnboardingQueue = lazy(() => import('containers/portal/hr/onboarding/onboarding-queue'));
const OnboardingQueueDetail = lazy(() =>
  import('containers/portal/hr/onboarding/onboarding-queue/queue-detail')
);

const DirectOnboard = lazy(() => import('containers/portal/hr/onboarding/direct-onboard'));
const OnboardEmployee = lazy(() => import('containers/portal/onboard/employee'));
const AddInfo = lazy(() => import('containers/portal/onboard/add-info'));

function AppRoutes() {
  return (
    <Suspense fallback={<GlobalLoader />}>
      <BrowserRouter>
        <ScrollToTop />

        <Routes>
          <Route path="/" element={<LayoutWrapper />}>
            {/* AUTH ROUTES */}
            <Route path="auth" element={<PublicRoutes />}>
              <Route path="login" element={<Login />} />
              <Route path="forgot-password" element={<ForgotPassword />} />
              <Route path="reset-password" element={<ResetPassword />} />
            </Route>

            {/* PORTAL ROUTES */}
            <Route path="/portal" element={<PrivateRoutes />}>
              <Route index element={<Dashboard />} />

              <Route path="pages" element={<Outlet />}>
                <Route path="user" element={<Outlet />}>
                  <Route path="profile" element={<UserProfile />} />
                  <Route path="calendar" element={<Calendar />} />
                  <Route path="signature" element={<Signature />} />
                </Route>

                <Route path="engagements" element={<Engagements />} />

                <Route path="hr" element={<Outlet />}>
                  <Route path="company" element={<Outlet />}>
                    <Route path="profiles" element={<CompanyProfiles />} />
                    <Route path="profiles/add" element={<AddCompanyProfile />} />
                    <Route path="profiles/edit" element={<EditCompanyProfile />} />
                    <Route path="files" element={<CompanyFiles />} />
                    <Route path="holidays" element={<CompanyHolidays />} />
                  </Route>

                  <Route path="payroll" element={<Outlet />}>
                    <Route path="salaries" element={<Outlet />}>
                      <Route index element={<Salary />} />

                      <Route path="details" element={<ViewSalaryDetails />} />
                    </Route>

                    <Route path="final-pay" element={<Outlet />}>
                      <Route index element={<FinalPay />} />
                      <Route path=":userId" element={<ViewFinalPayForm />} />
                    </Route>
                  </Route>

                  <Route path="employees" element={<Outlet />}>
                    <Route index element={<Employees />} />
                    <Route path=":userId/profile" element={<EmployeeProfile />} />
                    <Route path="add" element={<AddEmployee />} />
                  </Route>

                  <Route path="clients" element={<ClientsAccessWrapper />}>
                    <Route index element={<Clients />} />
                    <Route path="add" element={<AddClient />} />
                    <Route path=":id/detail" element={<ClientDetail />} />
                    <Route path=":id/edit" element={<EditClient />} />
                  </Route>

                  {/* SCREENING */}
                  <Route path="screening" element={<Outlet />}>
                    <Route
                      path="jobs"
                      element={(
                        <JobContextProvider>
                          <AdminAccessWrapper />
                        </JobContextProvider>
                      )}
                    >
                      <Route index element={<Jobs />} />
                      <Route path="add" element={<AddJob />} />
                      <Route path=":id/detail" element={<JobDetail />} />
                      <Route path=":id/edit" element={<EditJob />} />
                    </Route>

                    <Route path="interviews" element={<InterviewAccessWrapper> <Interviews /> </InterviewAccessWrapper>} />
                  </Route>

                  <Route path="hire-packet" element={<AdminAccessWrapper />}>
                    <Route path="create/:id" element={<CreateNewHirePacket />} />
                    <Route path="view/:id" element={<ViewHirePacket />} />
                  </Route>

                  {/* ONBOARDING */}
                  <Route path="onboarding" element={<AdminAccessWrapper />}>
                    <Route index element={<OnboardingQueue />} />
                    <Route path="detail/:id" element={<OnboardingQueueDetail />} />
                    <Route path="direct-onboard" element={<DirectOnboard />} />
                  </Route>
                </Route>

                <Route path="teams" element={<Outlet />}>
                  <Route index element={<Teams />} />
                  <Route path="add" element={<AddTeam />} />
                  <Route path=":id/detail" element={<TeamDetail />} />
                  <Route path=":id/edit" element={<EditTeam />} />
                </Route>

                <Route path="requests" element={<Outlet />}>
                  <Route index element={<Requests />} />
                  <Route path="leave-requests" element={<LeaveRequests />} />
                  <Route path="work-from-home-requests" element={<WorkFromHomeRequests />} />
                  <Route path="half-day-requests" element={<HalfDayRequests />} />
                  <Route path="document-requests" element={<DocumentRequests />} />
                  <Route path="resource-required" element={<ResourceRequired />} />
                </Route>

                <Route path="projects" element={<Outlet />}>
                  <Route index element={<Projects />} />
                  <Route path="add" element={<AddProject />} />
                  <Route path=":id/detail" element={<ProjectDetail />} />
                  <Route path=":id/edit" element={<EditProject />} />
                </Route>

                <Route path="todos" element={<Todos />} />

                <Route path="storage" element={<Outlet />}>
                  <Route path="trash" element={<Trash />} />
                  <Route path="main" element={<Storage />} />
                </Route>

                <Route path="settings" element={<Outlet />}>
                  <Route index element={<Settings />} />

                  <Route path="add" element={<AddSettings />} />
                  <Route path="edit" element={<AddSettings />} />
                </Route>

                <Route path="unauthorized" element={<Unauthorized />} />
              </Route>
            </Route>

            {/* GUEST ROUTES */}
            <Route path="/guest" element={<Outlet />}>
              <Route path="offer-letter/:id" element={<OfferLetter />} />
            </Route>

            {/* ONBOARD */}
            <Route path="/onboard" element={<OnboardRoutes />}>
              <Route path="employee" element={<OnboardEmployee />} />
              <Route
                path="add-info"
                element={(
                  <AddInfoContextProvider>
                    <AddInfo />
                  </AddInfoContextProvider>
                )}
              />
            </Route>

            {/* PUBLIC ROUTES */}
            <Route index element={<Homepage />} />

            <Route path="career" element={<Outlet />}>
              <Route path="details/:jobId" element={<CareerDetails />} />
              <Route path="apply/:jobId" element={<ApplyJob />} />

              <Route index element={<Careers />} />
            </Route>

            <Route path="*" element={<h1>Not Found!</h1>} />
          </Route>
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
}

export default AppRoutes;

const colors = {
  primary: '#006faf',
  secondary: '#e7e2e2',
  dark: '#646777',
  muted: '#999999',
  border: '#ced4da',
  disabled: '#f2f4f7',
  primaryHover: '#00598d',
  success: '#6cbf6c'
};

export const { primary, muted, secondary, dark, border, disabled } = colors;

export default colors;
